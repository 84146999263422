import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaBars } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom';

const FileGrid = () => {
  
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const user_id = localStorage.getItem('user_id');
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    // Fetch uploaded files data
    const fetchFiles = async () => {
      try {        
        const response = await axios.get('https://34m.2db.mytemp.website/InvoicelyApp/list_files.php?user_id='+user_id);
        setFiles(response.data.files);       
      } catch (error) {
        console.error('Error fetching files:', error);
      }finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev); // Toggle sidebar visibility
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn'); 
    navigate('/login');
  };

  const navigateToFiles = () => {   
    navigate('/dashboard');
  };

  // Function to handle file download
  const handleDownload = (fileid) => {
    
    window.location.href = `https://34m.2db.mytemp.website/InvoicelyApp/download_file.php?user_id=${user_id}&file_id=${fileid}`;
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading message or spinner
  }

  return (
<div>
      {/* Burger Icon to toggle sidebar */}
      <a href='#' className="burger-menu" onClick={toggleSidebar}>
        <FaBars />
      </a>

      {/* Sidebar Panel */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <h4 className="text-white">Menu</h4>
        <br></br>
        <ul className="text-white">
        <li><a href="#" onClick={navigateToFiles}>New Invoice</a></li>
        <li><a href="#" onClick={handleLogout}>Logout</a></li>
        </ul>
      </div>

    <div className="container mt-5">
      <h2>Uploaded Files</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>File Name</th>
            <th>Upload Date</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
        {Array.isArray(files) && files.length > 0 ? (
          files.map(file => (
            <tr key={file.id}>
              <td>{file.id}</td>
              <td>{file.file_name}</td>
              <td>{new Date(file.uploaded_at).toLocaleDateString()}</td>
              <td>
                <a href='#'
                className='Button'
                  variant="primary"
                  onClick={() => handleDownload(file.id)}
                >
                  Download
                </a>
              </td>
            </tr>
          ))) : (
            <p>No files available.</p>
          )}
        </tbody>
      </Table>
    </div>
    </div>
  );
};

export default FileGrid;
