import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Container from 'react-bootstrap/Container';
import Login from './components/Login';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from './components/Signup';
import InvoiceForm from './components/InvoiceForm';
import FileGrid from './components/FileGrid';

class App extends Component {
  render() {
  return (
    
      <Container>
      <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<InvoiceForm />} />
        <Route path="/files" element={<FileGrid />} />
      </Routes>
    </Router>
       
      </Container>
   
  );
}}

export default App;



