import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { BiCloudDownload } from "react-icons/bi";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const InvoiceModal = ({ showModal, closeModal, info, items, currency, total, subTotal, taxAmmount, discountAmmount }) => {
  const navigate = useNavigate();
  const user_id = localStorage.getItem('user_id');

  const GenerateInvoice = () => {
    html2canvas(document.querySelector("#invoiceCapture"), {
      scale: 2 // Increase scale for better quality
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0); // Set quality to maximum
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: [612, 792]
      });

      pdf.internal.scaleFactor = 1;

      // Set font size and color
      pdf.setFontSize(12); // Adjust font size as needed
      pdf.setTextColor(0, 0, 0); // Set font color to black (RGB)

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Add the image with maximum quality
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
      const pdfFileName = `invoice-${info.invoiceNumber || '001'}.pdf`;
      pdf.save(pdfFileName);

      // Prepare file for upload
      const pdfBlob = pdf.output('blob'); // Get the PDF as a Blob
      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('file', pdfBlob, pdfFileName);

      // Upload the PDF to your API
      axios.post('https://34m.2db.mytemp.website/InvoicelyApp/upload.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        console.log('File uploaded successfully:', response.data);
        navigate('/files'); // Navigate to '/files' after successful upload
      })
      .catch(error => {
        console.error('Error uploading file:', error);
      });
    });
  };

  return (
    <div>
      <Modal show={showModal} onHide={closeModal} size="xl" centered>
        <div id="invoiceCapture">
          <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-4">
            <div className="w-100">
              <h4 className="fw-bold my-2">{info.billFrom || 'John Uberbacher'}</h4>
              <h6 className="fw-bold text-secondary mb-1">
                Invoice #: {info.invoiceNumber || ''}
              </h6>
            </div>
            <div className="text-end ms-4">
              <h6 className="fw-bold mt-1 mb-2">Amount Due:</h6>
              <h5 className="fw-bold text-secondary" style={{ display: "inline-flex" }}>
                <span style={{ float: "left" }}> {currency} </span>&nbsp;<span style={{ float: "right" }}>{total}</span>
              </h5>
            </div>
          </div>
          <div className="p-4">
            <Row className="mb-4">
              <Col md={4}>
                <div className="fw-bold">Billed From:</div>
                <div>{info.billFrom || ''}</div>
                <div>{info.billFromAddress || ''}</div>
                <div>{info.billFromEmail || ''}</div>
              </Col>
              <Col md={4}>
                <div className="fw-bold">Billed to:</div>
                <div>{info.billTo || ''}</div>
                <div>{info.billToAddress || ''}</div>
                <div>{info.billToEmail || ''}</div>
              </Col>
              <Col md={4}>
                <div className="fw-bold mt-2">Date Of Issue:</div>
                <div>{info.dateOfIssue || ''}</div>
              </Col>
            </Row>
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>QTY</th>
                  <th>DESCRIPTION</th>
                  <th className="text-end">PRICE</th>
                  <th className="text-end">AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, i) => (
                  <tr key={i}>
                    <td style={{ width: '70px' }}>{item.quantity}</td>
                    <td>{item.name} - {item.description}</td>
                    <td className="text-end" style={{ width: '100px' }}>{currency} {item.price}</td>
                    <td className="text-end" style={{ width: '100px' }}>{currency} {item.price * item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Table>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>SUBTOTAL</td>
                  <td className="text-end" style={{ width: '100px' }}>{currency} {subTotal}</td>
                </tr>
                {taxAmmount !== 0.00 &&
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>TAX</td>
                    <td className="text-end" style={{ width: '100px' }}>{currency} {taxAmmount}</td>
                  </tr>
                }
                {discountAmmount !== 0.00 &&
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>DISCOUNT</td>
                    <td className="text-end" style={{ width: '100px' }}>{currency} {discountAmmount}</td>
                  </tr>
                }
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>TOTAL</td>
                  <td className="text-end" style={{ width: '100px' }}>{currency} {total}</td>
                </tr>
              </tbody>
            </Table>
            {info.notes &&
              <div className="bg-light py-3 px-4 rounded">
                {info.notes}
              </div>}
          </div>
        </div>
        <div className="pb-4 px-4">
          <Row>
            <Col md={6}></Col>
            <Col md={6}>
              <Button variant="outline-primary" className="d-block w-100 mt-3 mt-md-0" onClick={GenerateInvoice}>
                <BiCloudDownload style={{ width: '16px', height: '16px', marginTop: '-3px' }} className="me-2" />
                Download Copy
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      <hr className="mt-4 mb-3" />
    </div>
  );
};

export default InvoiceModal;
