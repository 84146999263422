import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    
    if (isLoggedIn) {
      // If not logged in, redirect to login
      navigate('/dashboard');
    }
  }, [navigate]);

  const [formData, setFormData] = useState({
    username: '',  
    password: ''
  });
  
  const [error, setError] = useState('');

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Form validation (you can expand this further)
  const validateForm = () => {
    const { username, password } = formData;
    if (!username || !password) {
      setError('All fields are required');
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post('https://34m.2db.mytemp.website/InvoicelyApp/login.php', formData);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('user_id', response.data.user.user_id);       
        navigate('/dashboard');
      } catch (error) {
        setError('Error: ' + (error.response ? error.response.data.message : error.message));
      }
    }
  };

  return (
    <div className="App d-flex flex-column align-items-center justify-content-center w-100">
    <div className="signup-form">
      <h2>Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}     
      <form onSubmit={handleSubmit}>
      

        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>

      

        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>


        <button type="submit">Sign In</button>
      </form>
      <br></br>
      <p>Don't have an account? <a href='#' onClick={() => navigate('/signup')}>Sign Up</a></p>
    </div>
    </div>
  );
};

export default Login;
